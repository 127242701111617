import React, { useEffect } from 'react';
import GlobalStyles from './styles';
import { Layout } from 'antd';
import {
  Appbar,
  Footer,
} from './components';
import {
  Home,
  AboutUs,
  Careers,
  Services,
  ContactUs,
} from './pages'
import {
  Route,
  Routes,
  BrowserRouter as Router,
  useLocation,
  Navigate,
} from 'react-router-dom';
import {
  HOME,
  ABOUT_US,
  OUR_SERVICES,
  CAREERS,
  CONTACT_US,
} from './route.constants';

const { Content } = Layout;

const Main = () => {
  return (
    <Routes>
      <Route exact path={HOME} element={<Home />} />
      <Route exact path={ABOUT_US} element={<AboutUs />} />
      <Route exact path={OUR_SERVICES} element={<Services />} />
      <Route exact path={CAREERS} element={<Careers />} />
      <Route exact path={CONTACT_US} element={<ContactUs />} />
      <Route exact path='*' element={<Navigate to='/' />} />
    </Routes>
  );
}

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, [pathname]);

  return null;
}

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <GlobalStyles />
      <Layout>
        <Appbar />
        <Content>
          <Main />
          <Footer />
        </Content>
      </Layout>
    </Router>
  );
}

export default App;
