import styled from "styled-components";
import Img from '../../../assets/about_img.png';

export const Container = styled.div`
padding: 70px 0;

.ant-row {
    align-items: center;
}
`;

export const Right = styled.div`
text-align: left;

@media screen and (max-width: 575px) {
    text-align: center;
}
`;

export const SubTitle = styled.div`
display: flex;
align-items: center;
justify-content: flex-start;
grid-gap: 12px;

font-size: 24px;
font-weight: 500;
font-style: normal;
padding: 25px 0;
color: #000;
text-transform: capitalize;

svg {
    color: #01B0EB;
    font-size: 20px;
    margin-bottom: -2px;
}

@media screen and (max-width: 1600px) {
    font-size: 19px;
}

`;

export const Descriptions = styled.div`
font-size: 18px;
font-weight: normal;
font-style: normal;
color: #015ABA;
line-height: 1.5;
letter-spacing: normal;
padding-bottom: 30px;
text-align: justify;

@media screen and (max-width: 1600px) {
    font-size: 16px;
}

@media screen and (max-width: 1200px) {
    font-size: 15px;
}

@media screen and (max-width: 992px) {
    font-size: 14px;
}

@media screen and (max-width: 768px) {
    font-size: 13px;
}
`;

export const Image = styled.img.attrs({
    alt: 'slideimg',
    src: Img,
})`
width: 100%;
`;