import { Col, Row } from 'antd';
import React from 'react';
import {
    BackgroundImage,
    Container,
    ContentWrapper,
    Text,
    Title,
} from './GetStarted.style';
import Button from '../../Button/Button';
import { useNavigate } from 'react-router-dom';
import { CONTACT_US } from '../../../route.constants';

const GetStarted = () => {
    const navigate = useNavigate();

    const handleClicked = () => {
        navigate(CONTACT_US);
    }
    
    return (
        <Row>
            <Col xs={1} xl={2} xxl={4} />
            <Col xs={22} xl={20} xxl={16} >
                <Container>
                    <BackgroundImage />
                    <ContentWrapper>
                        <Title>Let’s get started!</Title>
                        <Text>We are open for consulting, design and coding inquiries.</Text>
                        <Button onClick={handleClicked}>
                            Send Inquiries Email
                        </Button>
                    </ContentWrapper>
                </Container>
            </Col>
            <Col xs={1} xl={2} xxl={4} />
        </Row>
    );
}

export default GetStarted;