import React from 'react';
import styled from 'styled-components';
import mapImg from '../../../assets/map.png';

const Image = styled.img.attrs({
    alt: 'map-img',
    src: mapImg,
})`
width: 100%;
object-fit: cover;

@media screen and (max-width: 767px) {
    height: 100%;
}
`;

const Container = styled.div`

@media screen and (max-width: 767px) {
    height: 250px;
}
`;

const Map = () => {

    return (
        <Container>
            <Image />
        </Container>
    );
}

export default Map;