import React from 'react';
import {
    Topbar,
    Title,
    Description,
    OurServices,
    Products,
} from '../../components';
import styled from 'styled-components';
import { Col, Row } from 'antd';

const RootContainer = styled.div`
min-height: 100vh;
padding-bottom: 70px;
`;

const Container = styled.div`
width: 100%;
display: flex;
flex-direction: column;
gap: 40px;
padding: 70px 0;
justify-content: center;

@media screen and (max-width: 1600px) {
    padding: 60px 0;
}

@media screen and (max-width: 1200px) {
    padding: 45px 0;
    gap: 30px;
}

@media screen and (max-width: 768px) {
    gap: 20px;
}
`;

const TitleWrapper = styled.div`
text-align: center;

div {
    display: inline-flex;
}
`;

const Services = () => {

    const renderTitle = () => (
        <TitleWrapper>
            <Title>
                Our Services
            </Title>
        </TitleWrapper>
    );

    return (
        <RootContainer>
            <Topbar />
            <Row>
                <Col xs={1} xl={2} xxl={4} />
                <Col xs={22} xl={20} xxl={16}>
                    <Container>
                        {renderTitle()}
                        <Description />
                        <OurServices />
                    </Container>
                </Col>
                <Col xs={1} xl={2} xxl={4} />
            </Row>
            <Products />
        </RootContainer>
    );
}

export default Services;