export const positions = [
    {
        title: 'International Business Development Lead',
        category: 'Business Development',
        type: 'Full-Time',
        location: 'Yangon',
        last_apply_date: '30 November 2023',
        work_location: 'No.42, 3rd Floor, Kyauktada Township, Yangon, Myanmar.',
        working_hour: 'Monday - Friday (9:00 AM - 5:00 PM)',
        description: 'we are experts at building custom web apps to suit your business needs. We have experience with a different domains such as ERP, CRM, B2B & B2C e-commerce, and industries such as human resources, stock management, travel agencies real estate listings and insurance broker. We are using various open source frameworks depending on your project, get in touch with us discuss your needs.',
        requirements: {
            core_skills: [
                'ability to set goals',
                'empowering and developing your people and fostering a collaborative work environment',
                'ability to make the right decision',
            ],
            required_skills: [
                'ability to set goals',
                'empowering and developing your people and fostering a collaborative work environment',
                'ability to make the right decision',
            ],
            useful_skills: [
                'ability to set goals',
                'empowering and developing your people and fostering a collaborative work environment',
                'ability to make the right decision',
            ],
        },
    },
    {
        title: 'QA Tester',
        category: 'Tester',
        type: 'Full-Time',
        location: 'Yangon',
        last_apply_date: '30 November 2023',
        work_location: 'No.42, 3rd Floor, Kyauktada Township, Yangon, Myanmar.',
        working_hour: 'Monday - Friday (9:00 AM - 5:00 PM)',
        description: 'we are experts at building custom web apps to suit your business needs. We have experience with a different domains such as ERP, CRM, B2B & B2C e-commerce, and industries such as human resources, stock management, travel agencies real estate listings and insurance broker. We are using various open source frameworks depending on your project, get in touch with us discuss your needs.',
        requirements: {
            core_skills: [
                'ability to set goals',
                'empowering and developing your people and fostering a collaborative work environment',
                'ability to make the right decision',
            ],
            required_skills: [
                'ability to set goals',
                'empowering and developing your people and fostering a collaborative work environment',
                'ability to make the right decision',
            ],
            useful_skills: [
                'ability to set goals',
                'empowering and developing your people and fostering a collaborative work environment',
                'ability to make the right decision',
            ],
        },
    },
    {
        title: 'Flutter Developer',
        category: 'Developer',
        type: 'Full-Time',
        location: 'Yangon',
        last_apply_date: '30 November 2023',
        work_location: 'No.42, 3rd Floor, Kyauktada Township, Yangon, Myanmar.',
        working_hour: 'Monday - Friday (9:00 AM - 5:00 PM)',
        description: 'we are experts at building custom web apps to suit your business needs. We have experience with a different domains such as ERP, CRM, B2B & B2C e-commerce, and industries such as human resources, stock management, travel agencies real estate listings and insurance broker. We are using various open source frameworks depending on your project, get in touch with us discuss your needs.',
        requirements: {
            core_skills: [
                'ability to set goals',
                'empowering and developing your people and fostering a collaborative work environment',
                'ability to make the right decision',
            ],
            required_skills: [
                'ability to set goals',
                'empowering and developing your people and fostering a collaborative work environment',
                'ability to make the right decision',
            ],
            useful_skills: [
                'ability to set goals',
                'empowering and developing your people and fostering a collaborative work environment',
                'ability to make the right decision',
            ],
        },
    },
    {
        title: 'Web Developer',
        category: 'Developer',
        type: 'Full-Time',
        location: 'Yangon',
        last_apply_date: '30 November 2023',
        work_location: 'No.42, 3rd Floor, Kyauktada Township, Yangon, Myanmar.',
        working_hour: 'Monday - Friday (9:00 AM - 5:00 PM)',
        description: 'we are experts at building custom web apps to suit your business needs. We have experience with a different domains such as ERP, CRM, B2B & B2C e-commerce, and industries such as human resources, stock management, travel agencies real estate listings and insurance broker. We are using various open source frameworks depending on your project, get in touch with us discuss your needs.',
        requirements: {
            core_skills: [
                'ability to set goals',
                'empowering and developing your people and fostering a collaborative work environment',
                'ability to make the right decision',
            ],
            required_skills: [
                'ability to set goals',
                'empowering and developing your people and fostering a collaborative work environment',
                'ability to make the right decision',
            ],
            useful_skills: [
                'ability to set goals',
                'empowering and developing your people and fostering a collaborative work environment',
                'ability to make the right decision',
            ],
        },
    },
];