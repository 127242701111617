import { Col, Row } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { services } from '../../../settings';
import bgimg from '../../../assets/card_bg.png';

const Card = styled.div`
text-align: center;
`;

const Box = styled.div`
margin-top: -20px;
text-align: center;
border-radius: 10px;
position: relative;
`;

const BackgroundImage = styled.img.attrs({
    alt: 'bgimg',
    src: bgimg,
})`
width: 100%;
position: absolute;
top: 0;
left: 0;
height: 100%;
z-index: 0;
`;

const BoxContent = styled.div`
padding: 30px;
padding-bottom: 40px;
position: relative;
top: 0;
left: 0;
z-index: 2;

    @media screen and (max-width: 992px) {
        padding: 20px;
        padding-bottom: 35px;
    }
`;

const Text = styled.div`
font-size: 18px;
font-weight: normal;
font-style: normal;
letter-spacing: normal;
color: #000;
line-height: 1.5;

@media screen and (max-width: 1600px) {
    font-size: 16px;
}

@media screen and (max-width: 1200px) {
    font-size: 15px;
}

@media screen and (max-width: 992px) {
    font-size: 14px;
}

@media screen and (max-width: 768px) {
    font-size: 13px;
}
`;

const Title = styled(Text)`
font-size: 24px;
font-weight: 500;
padding-top: 50px;
padding-bottom: 30px;

    @media screen and (max-width: 1600px) {
    font-size: 20px;
    padding-top: 40px;
    padding-bottom: 20px;
    }

    @media screen and (max-width: 1200px) {
    font-size: 18px;
    padding-top: 40px;
    padding-bottom: 20px;
    }

    @media screen and (max-width: 992px) {
    font-size: 16px;
    padding-top: 40px;
    padding-bottom: 20px;
    }

    @media screen and (max-width: 768px) {
    font-size: 16px;
    padding-top: 35px;
    padding-bottom: 15px;
    }
`;

const Image = styled.img.attrs({
    alt: 'image',
})`
width: 180px;

@media screen and (max-width: 1600px) {
    width: 170px;
}

@media screen and (max-width: 1200px) {
    width: 150px;
}

@media screen and (max-width: 992px) {
    width: 120px;
}

@media screen and (max-width: 768px) {
    width: 100px;
}
`;

const Services = () => {
    return (
        <Row gutter={[30, 30]}>
            {services.map((service, key) => (
                <Col key={key} xs={24} md={12}>
                    <Card>
                        <Image src={service.image} />
                        <Box>
                            <BackgroundImage />
                            <BoxContent>
                                <Title>
                                    {service.label}
                                </Title>
                                <Text>
                                    {service.description}
                                </Text>
                            </BoxContent>
                        </Box>
                    </Card>
                </Col>
            ))}
        </Row>
    );
}

export default Services;