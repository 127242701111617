import { Space } from "antd";
import styled from "styled-components";

export const Container = styled.div`
padding-bottom: 70px;
text-align: center;
`;

export const TitleWrapper = styled.div`
display: flex;
align-items: center;
justify-content: center;
padding-bottom: 30px;
`;

export const Text = styled.div`
font-size: 18px;
font-weight: normal;
font-style: normal;
color: #000;
display: inline;
line-height: 1.5;

@media screen and (max-width: 1600px) {
    font-size: 17px;
}

@media screen and (max-width: 1200px) {
    font-size: 16px;
}

@media screen and (max-width: 992px) {
    font-size: 15px;
}

@media screen and (max-width: 768px) {
    font-size: 14px;
}

@media screen and (max-width: 576px) {
    font-size: 13px;
}
`;

export const Box = styled.div`
box-shadow: 0px 3px 4px #00000029;
border: 1px solid #DBDBDB;
border-radius: 26px;
margin-top: 40px;
margin-bottom: 30px;

.ant-space:last-child {
    border: none;
}
`;

export const Item = styled(Space)`
width: 100%;
padding: 32px;
border-bottom: 1px solid #DBDBDB;
align-items: flex-start;
cursor: pointer;

.ant-space-item:nth-child(1) {
    flex: 1;
    text-align: left;
}

@media screen and (max-width: 1600px) {
    padding: 28px;
}

@media screen and (max-width: 1200px) {
    padding: 25px;
}

@media screen and (max-width: 992px) {
    padding: 22px;
}

@media screen and (max-width: 768px) {
    padding: 20px;
}

@media screen and (max-width: 576px) {
    padding: 15px;
}
`;

export const TextBold = styled(Text)`
font-weight: 500;
`;

export const TextBlue = styled(Text)`
color: #015ABA;
font-weight: 500;
padding-top: 5px;
`;