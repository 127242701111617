import React from 'react';
import {
    Col,
    Row,
} from 'antd';
import {
    Container,
    Right,
    SubTitle,
    Descriptions,
    Image,
} from './About.style';
import { MenuOutlined } from '@ant-design/icons';
import Button from '../../Button/Button';
import Title from '../../Title/Title';
import { useNavigate } from 'react-router-dom';
import { ABOUT_US } from '../../../route.constants';

const About = () => {
    const navigate = useNavigate();

    const handleClicked = () => {
        navigate(ABOUT_US);
    }

    return (
        <Container>
            <Row>
                <Col xs={1} sm={1} xl={2} xxl={4} />
                <Col xs={22} sm={11} xl={10} xxl={8}>
                    <Image />
                </Col>
                <Col xs={1} sm={0} />
                <Col xs={1} sm={2} xl={2} xxl={2} />
                <Col xs={22} sm={9} xl={8} xxl={6}>
                    <Right>
                        <Title>About Us</Title>
                        <SubTitle>
                            <MenuOutlined />
                            <span>Who We Are</span>
                        </SubTitle>
                        <Descriptions>
                            DIMINGALARGIT DIGITAL CO., LTD (DMG) is a leading technology company located in Yangon and now servicing about Web design and mobile application to our clients. We only focus on clients’ need to develop more qualified and smart projects. DMG’ software development services are specialized to meet the unique needs of businesses of all market sizes. The company works with a variety of programming languages and frameworks to develop more projects. Ours Mobile development team specializes in creating client’s desired custom mobile apps for Android and iOS platforms.
                        </Descriptions>
                        <Button onClick={handleClicked}>
                            Explore About Us
                        </Button>
                    </Right>
                </Col>
                <Col xs={1} sm={1} xl={2} xxl={4} />
            </Row>
        </Container>
    )
}

export default About;