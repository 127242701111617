import { Col, Row } from "antd";
import React from "react";
import {
	Bottom,
	Container,
	Text,
	Title,
	Logo,
	LogoWrapper,
} from "./Footer.style";
// import { FacebookIcon, TwitterIcon, TelegramIcon } from "../../styles";

const Footer = () => {
	return (
		<Container>
			<Row gutter={[40, 40]}>
				<Col xs={0} sm={0} xl={0} xxl={3} />
				<Col xs={24} sm={8} xl={8} xxl={6}>
					<LogoWrapper>
						<Logo />
					</LogoWrapper>
				</Col>
				<Col xs={24} sm={8} xl={8} xxl={6}>
					<Title>Our Address</Title>
					<Text>No.42, 3rd Floor, Kyauktada Township, Yangon, Myanmar.</Text>
				</Col>
				<Col xs={24} sm={8} xl={8} xxl={6}>
					<Title>Contact Us</Title>
					<Text>Phone - 09442947960</Text>
					<Text>Email - mingalardigitalmm@gmail.com</Text>
				</Col>
				<Col xs={0} sm={0} xl={0} xxl={3} />
			</Row>
			<Row>
				<Col xs={1} xl={2} xxl={4} />
				<Col xs={22} xl={20} xxl={16}>
					<Bottom>
						© 2023 DIMINGALARGIT DIGITAL CO., LTD All Rights Reserved.
					</Bottom>
				</Col>
				<Col xs={1} xl={2} xxl={4} />
			</Row>
		</Container>
	);
};

export default Footer;
