import React, { useState } from 'react';
import {
    Col,
    Row,
} from 'antd';
import {
    Container,
    Left,
    SubTitle,
    Descriptions,
    Card,
    Label,
    Grid,
} from './Services.style';
import { MenuOutlined } from '@ant-design/icons';
import Button from '../../Button/Button';
import Title from '../../Title/Title';
import { services } from '../../../settings';
import { useNavigate } from 'react-router-dom';
import { OUR_SERVICES } from '../../../route.constants';

const ImageCard = ({
    src,
    hoverSrc,
    label,
    handleChange,
}) => {
    const [hover, setHover] = useState(false);

    return (
        <Card
            onMouseEnter={() => {
                setHover(true);
                handleChange();
            }}
            onMouseLeave={() => setHover(false)}
        >
            <img alt='img' src={hover ? hoverSrc : src} />
            <Label>{label}</Label>
        </Card>
    );
}

const ImageGrid = ({
    handleChange,
}) => {
    return (
        <Grid>
            {services.map((service, key) => (
                <Col
                    key={key}
                    xs={12}
                >
                    <ImageCard
                        src={service.icon}
                        hoverSrc={service.altIcon}
                        label={service.label}
                        handleChange={() => handleChange(service)}
                    />
                </Col>
            ))}
        </Grid>
    );
}

const Services = () => {
    const navigate = useNavigate();
    const [current, setCurrent] = useState(services[0]);

    const handleClicked = () => {
        navigate(OUR_SERVICES);
    }

    return (
        <Container>
            <Row>
                <Col xs={1} sm={1} xl={2} xxl={4} />
                <Col xs={22} sm={10} xl={9} xxl={7}>
                    <Left>
                        <Title>Our Services</Title>
                        <SubTitle>
                            <MenuOutlined />
                            <span>{current.title}</span>
                        </SubTitle>
                        <Descriptions>
                            {current.description}
                        </Descriptions>
                        <Button onClick={handleClicked}>
                            Explore our services
                        </Button>
                    </Left>
                </Col>
                <Col xs={1} sm={0} />
                <Col xs={1} sm={2} xl={2} xxl={2} />
                <Col xs={22} sm={10} xl={9} xxl={7}>
                    <ImageGrid
                        handleChange={selected => setCurrent(selected)}
                    />
                </Col>
                <Col xs={1} sm={1} xl={2} xxl={4} />
            </Row>
        </Container>
    )
}

export default Services;