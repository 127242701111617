import React from 'react';
import Button from '../../Button/Button';
import styled from 'styled-components';
import { Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import { CONTACT_US } from '../../../route.constants';

const Text = styled.div`
font-size: 18px;
font-weight: normal;
font-style: normal;
letter-spacing: normal;
text-align: center;
line-height: 1.5;

@media screen and (max-width: 1600px) {
    font-size: 16px;
}

@media screen and (max-width: 1200px) {
    font-size: 15px;
}

@media screen and (max-width: 992px) {
    font-size: 14px;
}

@media screen and (max-width: 768px) {
    font-size: 13px;
}
`;

const TagLine = styled(Text)``;

const ButtonWrapper = styled.div`
display: inline;
text-align: center;

@media screen and (max-width: 767px) {
    padding-bottom: 20px;
}
`;

const Description = () => {
    const navigate = useNavigate();

    const handleClicked = () => {
        navigate(CONTACT_US);
    }

    return (
        <>
            <Row>
                <Col xs={0} sm={2} xl={4} />
                <Col xs={24} sm={20} xl={16}>
                    <TagLine>
                        Delivering Technology Solutions!
                    </TagLine>
                    <Text>
                        We are passionate about building beautiful digital experiences. More than a web development company or web design agency,we value the best technologies, tools and processes to deliver successful projects.
                    </Text>
                </Col>
                <Col xs={0} sm={2} xl={4} />
            </Row>
            <ButtonWrapper>
                <Button onClick={handleClicked}>
                    Get Services
                </Button>
            </ButtonWrapper>
        </>
    );
}

export default Description;