import styled from 'styled-components';
import {
    Modal as AntModal,
    Space as AntSpace,
} from 'antd';

export const Modal = styled(AntModal)`
width: 60vw !important;

.ant-modal-content {
    padding: 50px 100px !important;

    @media screen and (max-width: 1600px) {
        padding: 40px 80px !important;
    }

    @media screen and (max-width: 1200px) {
        padding: 30px 60px !important;
    }

    @media screen and (max-width: 992px) {
        padding: 20px 40px !important;
    }

    @media screen and (max-width: 768px) {
        padding: 15px 24px 20px 24px !important;
    }
}

.ant-modal-title {
    font-size: 45px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: normal;
    line-height: 1.5;
    color: #0165C0;

    padding-bottom: 13px;

    @media screen and (max-width: 1600px) {
    font-size: 30px;
    padding-bottom: 5px;
    }

    @media screen and (max-width: 1200px) {
    font-size: 28px;
    }

    @media screen and (max-width: 992px) {
    font-size: 25px;
    }

    @media screen and (max-width: 768px) {
    font-size: 20px;
    }
}

.ant-modal-close {
    width: 50px;

    @media screen and (max-width: 1600px) {
    width: 35px;
    }

    @media screen and (max-width: 1200px) {
    width: 30px;
    }

    @media screen and (max-width: 992px) {
    width: 25px;
    }

    @media screen and (max-width: 768px) {
    width: 25px;
    }
}

.anticon-close-circle {
    font-size: 50px;
    color: #015ABA;

    @media screen and (max-width: 1600px) {
    font-size: 35px;
    }

    @media screen and (max-width: 1200px) {
        font-size: 30px;
    }

    @media screen and (max-width: 992px) {
        font-size: 25px;
    }

    @media screen and (max-width: 768px) {
        font-size: 25px;
    }
}

    @media screen and (max-width: 1600px) {
        width: 60vw !important;
    }

    @media screen and (max-width: 1200px) {
        width: 70vw !important;
    }

    @media screen and (max-width: 992px) {
        width: 80vw !important;
    }

    @media screen and (max-width: 768px) {
        width: 80vw !important;
    }

    @media screen and (max-width: 575px) {
        width: 95vw !important;
    }
`;

export const Container = styled(AntSpace).attrs({
    direction: 'vertical',
})`
width: 100%;
gap: 30px !important;

>.ant-space-item {
    flex: 1;
    width: 100%;
}

    @media screen and (max-width: 1600px) {
        gap: 25px !important;
    }

    @media screen and (max-width: 1200px) {
        gap: 20px !important;
    }

    @media screen and (max-width: 992px) {
        gap: 15px !important;
    }
`;

export const Location = styled(AntSpace)`

.anticon-environment {
    font-size: 24px;

    @media screen and (max-width: 1600px) {
    font-size: 20px;
    }

    @media screen and (max-width: 1200px) {
    font-size: 18px;
    }

    @media screen and (max-width: 992px) {
    font-size: 16px;
    }

    @media screen and (max-width: 768px) {
    font-size: 15px;
    }
}
`;

export const LocationText = styled.div`
font-size: 18px;
font-weight: 500;
font-style: normal;
letter-spacing: normal;
color: #000;

    @media screen and (max-width: 1600px) {
    font-size: 16px;
    }

    @media screen and (max-width: 1200px) {
    font-size: 15px;
    }

    @media screen and (max-width: 992px) {
    font-size: 14px;
    }

    @media screen and (max-width: 768px) {
    font-size: 13px;
    }
`;

export const DateBox = styled.div`
padding: 10px 24px;
display: inline-block;
background: #015ABA;
border-radius: 12px;

font-size: 18px;
font-weight: normal;
font-style: normal;
letter-spacing: normal;
color: #fff;

    @media screen and (max-width: 1600px) {
    font-size: 16px;
    padding: 7px 20px;
    }

    @media screen and (max-width: 1200px) {
    font-size: 15px;
    padding: 7px 18px;
    }

    @media screen and (max-width: 992px) {
    font-size: 14px;
    padding: 5px 15px;
    }

    @media screen and (max-width: 768px) {
    font-size: 13px;
    padding: 3px 12px;
    }
`;

export const SectionBox = styled(AntSpace).attrs({
    size: 'middle'
})`
width: 100%;
margin-left: -100px;
padding: 20px 100px;
background: #CEE8FF;

font-size: 24px;
font-weight: 500;
font-style: normal;
letter-spacing: normal;
color: #0F173B;
text-transform: uppercase;

.anticon-menu {
    font-size: 24px;
    color: #01B0EB;
}

    @media screen and (max-width: 1600px) {
        font-size: 20px;
        width: 100%;
        margin-left: -80px;
        padding: 12px 80px;

        .anticon-menu {
            font-size: 20px;
        }
    }

    @media screen and (max-width: 1200px) {
        font-size: 18px;
        width: 100%;
        margin-left: -60px;
        padding: 12px 60px;
        
        .anticon-menu {
            font-size: 18px;
        }
    }

    @media screen and (max-width: 992px) {
        font-size: 16px;
        width: 100%;
        margin-left: -40px;
        padding: 10px 40px;
        
        .anticon-menu {
            font-size: 16px;
        }
    }

    @media screen and (max-width: 768px) {
        font-size: 15px;
        width: 100%;
        margin-left: -24px;
        padding: 5px 24px;
        
        .anticon-menu {
            font-size: 15px;
        }
    }
`;

export const SectionDesc = styled.div`
font-size: 18px;
font-weight: normal;
font-style: normal;
letter-spacing: normal;
color: #000;

    @media screen and (max-width: 1600px) {
    font-size: 16px;
    }

    @media screen and (max-width: 1200px) {
    font-size: 15px;
    }

    @media screen and (max-width: 992px) {
    font-size: 14px;
    }

    @media screen and (max-width: 768px) {
    font-size: 13px;
    }
`;

export const SectionLabel = styled.div`
font-size: 24px;
font-weight: normal;
font-style: normal;
letter-spacing: normal;
color: #000;

    @media screen and (max-width: 1600px) {
    font-size: 20px;
    }

    @media screen and (max-width: 1200px) {
    font-size: 18px;
    }

    @media screen and (max-width: 992px) {
    font-size: 16px;
    }

    @media screen and (max-width: 768px) {
    font-size: 15px;
    }
`;

export const SectionSpace = styled(AntSpace).attrs({
    direction: 'vertical'
})`
width: 100%;
gap: 30px !important;

    @media screen and (max-width: 1600px) {
        gap: 20px !important;
    }

    @media screen and (max-width: 1200px) {
        gap: 15px !important;
    }

    @media screen and (max-width: 992px) {
        gap: 13px !important;
    }
`;