import React from 'react';
import styled from 'styled-components';

const Bar = styled.div`
height: 180px;
background-color: #0F173B;
position: sticky;
top: 0;

@media screen and (max-width: 1600px) {
height: 120px;
}

@media screen and (max-width: 1200px) {
height: 100px;
}

@media screen and (max-width: 992px) {
height: 90px;
}

@media screen and (max-width: 768px) {
height: 80px;
}
`;

const Topbar = () => {
    return (
        <Bar />
    );
}

export default Topbar;