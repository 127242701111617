import { Space } from "antd";
import styled from "styled-components";
import LogoImg from "../../assets/logo.png";

export const Container = styled.div`
	height: 100%;
	background: #0f173b;
	overflow: hidden;
	position: relative;
	padding-top: 60px;

	.ant-row {
		align-items: flex-start;
	}

	@media screen and (max-width: 575px) {
		padding-top: 40px;

		.ant-row {
			row-gap: 25px !important;
		}
	}
`;

export const Title = styled.div`
	font-size: 24px;
	font-weight: normal;
	font-style: normal;
	color: #fff;
	text-align: center;
	padding-bottom: 45px;

	@media screen and (max-width: 1600px) {
		font-size: 22px;
		padding-bottom: 40px;
	}

	@media screen and (max-width: 1200px) {
		font-size: 18px;
		padding-bottom: 35px;
	}

	@media screen and (max-width: 992px) {
		font-size: 16px;
		padding-bottom: 30px;
	}

	@media screen and (max-width: 768px) {
		font-size: 14px;
		padding-bottom: 30px;
	}

	@media screen and (max-width: 575px) {
		padding-bottom: 14px;
	}
`;

export const Text = styled.div`
	font-size: 18px;
	font-weight: normal;
	font-style: normal;
	color: #eeeeee;
	text-align: center;
	line-height: 1.5;

	@media screen and (max-width: 1600px) {
		font-size: 16px;
	}

	@media screen and (max-width: 1200px) {
		font-size: 15px;
	}

	@media screen and (max-width: 992px) {
		font-size: 14px;
	}

	@media screen and (max-width: 768px) {
		font-size: 13px;
	}

	@media screen and (max-width: 575px) {
		padding-left: 5%;
		padding-right: 5%;
		font-size: 12px;
	}
`;

export const IconWrapper = styled(Space).attrs({
	size: "large",
})`
	width: 100%;
	justify-content: center;
	align-items: center;

	@media screen and (max-width: 1600px) {
		gap: 20px !important;
	}

	@media screen and (max-width: 1200px) {
		gap: 16px !important;
	}
`;

export const Bottom = styled.div`
	padding: 16px 0;
	margin-top: 40px;
	border-top: 1px solid #cee8ff;
	text-align: center;

	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	color: #eeeeee;

	@media screen and (max-width: 1600px) {
		font-size: 15px;
	}

	@media screen and (max-width: 1200px) {
		font-size: 14px;
	}

	@media screen and (max-width: 992px) {
		font-size: 13px;
	}

	@media screen and (max-width: 919px) {
		font-size: 13px;
		margin-top: 0;
	}

	@media screen and (max-width: 768px) {
		font-size: 12px;
	}

	@media screen and (max-width: 575px) {
		margin-top: 30px;
	}
`;

export const Logo = styled.img.attrs({
	alt: "logo",
	src: LogoImg,
})`
	width: 300px;
	height: auto;
	object-fit: contain;

	@media screen and (max-width: 1600px) {
		width: 220px;
	}

	@media screen and (max-width: 1200px) {
		width: 200px;
	}

	@media screen and (max-width: 992px) {
		width: 160px;
	}

	@media screen and (max-width: 768px) {
		width: 150px;
	}
`;

export const LogoWrapper = styled.div`
	display: flex;
	justify-content: center;
`;
