import React from 'react';
import {
    FindPosition,
    Topbar,
} from '../../components';
import styled from 'styled-components';

const RootContainer = styled.div`
min-height: 70vh;
`;


const Container = styled.div`
width: 100%;
display: flex;
flex-direction: column;
gap: 40px;
padding: 70px 0;
justify-content: center;

@media screen and (max-width: 1600px) {
    padding: 60px 0;
}

@media screen and (max-width: 1200px) {
    padding: 45px 0;
    gap: 30px;
}

@media screen and (max-width: 768px) {
    gap: 20px;
}
`;

const Careers = () => {
    return (
        <RootContainer>
            <Topbar />
            <Container>
                <FindPosition isHome={false} />
            </Container>
        </RootContainer>
    );
}

export default Careers;