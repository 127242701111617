import styled from "styled-components";
import {
    Layout,
    Menu as AntMenu,
    Space as AntSpace,
    Drawer as AntDrawer,
} from 'antd';
import LogoImg from '../../assets/logo.png';
import MobileLogoImg from '../../assets/mobile_logo.png';
import MenuIconImg from '../../assets/linx_icon.png';
import { MenuOutlined } from "@ant-design/icons";

const { Header: AntHeader } = Layout;

export const Header = styled(AntHeader)`
height: 180px !important;
width: 100%;
position: fixed;
top: 0;
z-index: 10;
background: ${props => props.color === 'filled' ? '#0F173B !important' : 'transparent !important'};
padding-inline: 0 !important;

.ant-row, .ant-col {
    height: 100%;
}

@media screen and (max-width: 1600px) {
height: 120px !important;
}

@media screen and (max-width: 1200px) {
height: 100px !important;
}

@media screen and (max-width: 992px) {
height: 90px !important;
}

@media screen and (max-width: 768px) {
height: 80px !important;
}
`;

export const Space = styled(AntSpace)`
width: 100%;
height: 100%;

.ant-space-item:nth-child(1) {
display: flex;
align-items: center;
}

.ant-space-item:nth-child(2) {
    flex: 1;
    
    ul {
        justify-content: flex-end;
    }
}
`;

export const Logo = styled.img.attrs({
    alt: 'logo',
    src: LogoImg,
})`
width: 300px;
height: auto;
object-fit: contain;
cursor: pointer;

@media screen and (max-width: 1600px) {
width: 220px;
}

@media screen and (max-width: 1200px) {
width: 200px;
}

@media screen and (max-width: 992px) {
width: 160px;
}

@media screen and (max-width: 768px) {
width: 150px;
}
`;

export const MobileLogo = styled(Logo).attrs({
    src: MobileLogoImg,
})``;

export const Menu = styled(AntMenu)`
border: none !important;
background-color: transparent !important;
line-height: initial !important;
align-items: center !important;

.ant-menu-item:nth-child(5) {
    padding-right: 0 !important;
}

.ant-menu-item {
    padding-inline: 35px;

    .ant-menu-title-content {
        font-size: 18px;
        font-weight: 600;
        font-style: normal;
        color: #FFF;
        text-transform: uppercase;
    }

    :not(.ant-menu-item-selected) {
        .ant-menu-item-icon {
            position: absolute;
            display: none;
        }
    }

    :hover {

        .ant-menu-title-content {
            color: #01B0EB;
        }

        ::after{
            border: none !important;
        }
    }

    ::after {
        border: none !important;
    }
}

.ant-menu-item-selected {

    .ant-menu-title-content {
        color: #01B0EB;
    }

    .ant-menu-item-icon {
        position: absolute;
        display: inline-flex;
    }

    ::after {
        border: none !important;
    }
}

.ant-menu-item-icon {
    width: calc(100% - 32px);
}


@media screen and (max-width: 1600px) {
    .ant-menu-item {
        padding-inline: 25px;

        .ant-menu-title-content {
            font-size: 16px;
        }
    }
}

@media screen and (max-width: 1200px) {
    .ant-menu-item {
        padding-inline: 20px;

        .ant-menu-title-content {
            font-size: 14px;
        }
    }
}

@media screen and (max-width: 992px) {
    .ant-menu-item {
        padding-inline: 10px;

        .ant-menu-title-content {
            font-size: 13px;
        }
    }
}

@media screen and (max-width: 768px) {
    .ant-menu-item {
        padding-inline: 5px;

        .ant-menu-title-content {
            font-size: 12px;
        }
    }
}

@media screen and (max-width: 768px) {
display: none;
}
`;

export const MenuIcon = styled.img.attrs({
    alt: 'menuicon',
    src: MenuIconImg,
})`
width: 42px;
position: absolute;
top: -10px;
left: -7px;

@media screen and (max-width: 1200px) {
width: 37px;
top: -10px;
left: -5px;
}

@media screen and (max-width: 768px) {
width: 28px;
top: -5px;
left: -1px;
}
`;

export const Indicator = styled.div`
position: absolute;
width: 22px;
border-bottom: 3px solid #015ABA;
bottom: -27px;
left: 11px;

@media screen and (max-width: 1600px) {
}

@media screen and (max-width: 1200px) {
    bottom: -24px;
}

@media screen and (max-width: 992px) {
    bottom: -24px;
}

@media screen and (max-width: 768px) {
    bottom: -21px;
    border-bottom: 2px solid #015ABA;
}
`;

export const IconWrapper = styled.div.attrs({
    className: 'ant-menu-item-icon',
})``;

export const ContactButton = styled.div`
padding: 15px;
border-radius: 26px;
background: #015ABA;
box-shadow: 0 2px 0 rgb(5 145 255 / 10%);
transition: all .2s cubic-bezier(.645,.045,.355,1);
touch-action: manipulation;

font-size: 18px;
font-weight: normal;
color: #FFF !important;

:hover {
    color: #FFF !important;
    background: #006fe7;
}

:active {
    background: #004a9b;
}

:focus, :focus-visible {
    outline: 2px solid #016ee3;
    outline-offset: 1px;
    transition: outline-offset 0s,outline 0s;
}

@media screen and (max-width: 1600px) {
    font-size: 16px;
    padding: 14px;
}

@media screen and (max-width: 1200px) {
    font-size: 14px;
}

@media screen and (max-width: 992px) {
    font-size: 13px;
    padding: 12px;
}

@media screen and (max-width: 768px) {
    font-size: 12px;
    padding: 10px;
}
`;

export const MobileMenuIcon = styled(MenuOutlined)`
display: none;

svg {
    color: #fff;
    font-size: 25px;
}

@media screen and (max-width: 768px) {
display: block;
}
`;

export const Nav = styled.div``;

export const NavItem = styled.div`
margin: 30px 0;
text-align: center;
font-size: 12px;
font-weight: 600;
font-style: normal;
text-transform: uppercase;
line-height: 2;

    ${Nav} {
        position: relative;
        display: inline;
    }

    ${MenuIcon} {
        left: -12px;
        top: -7px;
    }

    ${Indicator} {
        width: 17px;
        left: 0;
        bottom: -5px;
    }

    :last-child {
        ${MenuIcon} {
            display: none;
        }
        ${Indicator} {
            display: none;
        }
    }
`;

export const Drawer = styled(AntDrawer)`

.ant-drawer-header-title {
    flex-direction: row-reverse;
}

.ant-drawer-header, .ant-drawer-body {
    padding: 16px 18px;
}

.ant-drawer-close {
    margin-inline-end: 0;
    
    .anticon-close {
        font-size: 25px;
    }
}

${Logo} {
    width: 120px;
}
`;