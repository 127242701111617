import React from 'react';
import {
    Banner,
    Services,
    GetStarted,
    About,
    // FindPosition,
} from '../../components';

const Home = () => {
    return (
        <>
            <Banner />
            <Services />
            <GetStarted />
            <About />
            {/* <FindPosition /> */}
        </>
    );
}

export default Home;