import { Row } from "antd";
import styled from "styled-components";

export const Container = styled.div`
padding: 120px 0 70px 0;

.ant-row {
    align-items: center;
}

@media screen and (max-width: 1600px) {
    padding: 90px 0 70px 0;
}

@media screen and (max-width: 1200px) {
    padding: 80px 0 70px 0;
}

@media screen and (max-width: 575px) {
    padding: 60px 0 70px 0;
}
`;

export const Left = styled.div`
text-align: left;

@media screen and (max-width: 575px) {
    text-align: center;
    padding-bottom: 80px;
}
`;

export const SubTitle = styled.div`
display: flex;
align-items: center;
justify-content: flex-start;
grid-gap: 12px;

font-size: 24px;
font-weight: 500;
font-style: normal;
padding: 25px 0;
color: #000;
text-transform: capitalize;

svg {
    color: #01B0EB;
    font-size: 20px;
    margin-bottom: -2px;
}

@media screen and (max-width: 1600px) {
    font-size: 19px;
}
`;

export const Descriptions = styled.div`
font-size: 18px;
font-weight: normal;
font-style: normal;
color: #000;
line-height: 1.5;
letter-spacing: normal;
padding-bottom: 30px;
text-align: left;
text-align: justify;

@media screen and (max-width: 1600px) {
    font-size: 16px;
}

@media screen and (max-width: 1200px) {
    font-size: 15px;
}

@media screen and (max-width: 992px) {
    font-size: 14px;
}

@media screen and (max-width: 768px) {
    font-size: 13px;
}
`;

export const Label = styled.div`
font-weight: 600;
font-style: normal;
font-size: 18px;
letter-spacing: normal;
color: #000;
transition: 0.3s;

@media screen and (max-width: 1600px) {
    font-size: 16px;
}

@media screen and (max-width: 1200px) {
    font-size: 15px;
}

@media screen and (max-width: 992px) {
    font-size: 14px;
}

@media screen and (max-width: 768px) {
    font-size: 13px;
}
`;

export const Card = styled.div`
background: #DCEFFF;
box-shadow: 0px 3px 6px #CEE8FF40;
border-radius: 12px;
padding: 40px 0;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
gap: 27px;
overflow: hidden;
position: relative;
transition: all 0.3s;
z-index: 1;
cursor: pointer;

    :after{
        content: '';
        position: absolute;
        z-index: -1;
        transition: all 0.3s;
        box-sizing: border-box;
        border-radius: 12px;
    }

    :after {
        width: 100%;
        height: 0;
        top: 0;
        left: 0;
        background: #0165C0;
    }

    :hover,
    :active {
        ${Label} {
            color: #fff;
        }
    }

    :hover:after,
    :active:after {
        height: 100%;
    }

img {
    width: 120px;
}

@media screen and (max-width: 1600px) {

    img {
        width: 100px;
    }
}

@media screen and (max-width: 1200px) {

    img {
        width: 80px;
    }
}

@media screen and (max-width: 992px) {

    img {
        width: 60px;
    }
}

@media screen and (max-width: 768px) {
    padding: 30px 0;

    img {
        width: 50px;
    }
}

@media screen and (max-width: 575px) {

    img {
        width: 50px;
    }
}
`;

export const Grid = styled(Row).attrs({
    gutter: [20, 20],
})`

.ant-col:nth-child(2), .ant-col:nth-child(4) {
    margin-top: -100px;
}
`;