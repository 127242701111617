import web_app_img from '../assets/web_app.png';
import mobile_app_img from '../assets/mobile_app.png';
import software_img from '../assets/software.png';
import uiux_img from '../assets/uiux.png';
import alt_web_app_img from '../assets/web_app_alt.png';
import alt_mobile_app_img from '../assets/mobile_app_alt.png';
import alt_software_img from '../assets/software_alt.png';
import alt_uiux_img from '../assets/uiux_alt.png';
import mobile_line_img from '../assets/mobile_line.png';
import web_line_img from '../assets/web_line.png';
import software_line_img from '../assets/software_line.png';
import uiux_line_img from '../assets/uiux_line.png';

export const services = [
    {
        title: 'Web Application Solutions',
        description: "Web services are a type of software system designed to facilitate communication and data exchange between different web applications over the internet. A web application is a software program that runs on a web server and is accessed through a web browser. Web applications can range from simple static websites to complex dynamic applications with sophisticated functionality. Web applications are ubiquitous in today's digital world and are used for a wide range of purposes, including e-commerce, social networking, and business management.",
        icon: web_app_img,
        altIcon: alt_web_app_img,
        image: web_line_img,
        label: 'Web Application',
    },
    {
        title: 'Mobile Application Solutions',
        description: "Mobile technology's growth has led to challenges in planning projects. Choosing between native apps and web apps is essential due to multiple platforms and hardware manufacturers. With tablets, wearables, and other devices, mobile tech is no longer limited to phones. Stay ahead of the competition with our expert mobile app development solutions. Mobile app development technology refers to the various tools, frameworks, components, libraries, and other technologies used to create applications for mobile devices. Examples of mobile devices include smartphones, laptops, tablets, and smartwatches. The two most dominant mobile platforms on the market are Android and iOS.",
        icon: mobile_app_img,
        altIcon: alt_mobile_app_img,
        image: mobile_line_img,
        label: 'Mobile Application',
    },
    {
        title: 'Software Outsourcing',
        description: "Companies outsource software development for two primary reasons – cost control and speed. They can keep the software development cost within the budget and build effective solutions within a short time. Besides, outsourcing software development has other advantages such as improved efficiency, mitigated risk, and enhanced security. Both established companies and startups are outsourcing their software development work. From choosing the right vendor to ensuring the quality of work delivered, there are many challenges that arise when outsourcing software development.",
        icon: software_img,
        altIcon: alt_software_img,
        image: software_line_img,
        label: 'Software Outsourcing',
    },
    {
        title: 'UI/UX Design',
        description: "We live in a beautiful era of design, with new techniques and trends coming and going. Frontend development is also gaining increased momentum, with more and more developers getting involved in UI/UX design. While both UI and UX design are often placed on the same pedestal, they are fundamentally different, and beginners should understand the difference between the two. A UI (User Interface) deals with the application's graphical layout, which includes buttons, screen layout, animations, transitions, micro-interactions, and so on. In short, UI is all about how things look. UX (User Experience) design deals with how users interact with the system. Logical navigation and how smooth and intuitive the experience is all fall under UX design. In short, this type of design helps users have a positive experience.",
        icon: uiux_img,
        altIcon: alt_uiux_img,
        image: uiux_line_img,
        label: 'UI/UX Design',
    },
];