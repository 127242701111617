import { Col, Row } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { ContactForm, Map, Title, Topbar } from '../../components';

const RootContainer = styled.div`
min-height: 100vh;
`;

const Container = styled.div`
width: 100%;
display: flex;
flex-direction: column;
gap: 40px;
padding: 70px 0;
justify-content: center;

@media screen and (max-width: 1600px) {
    padding: 60px 0;
}

@media screen and (max-width: 1200px) {
    padding: 45px 0;
    gap: 30px;
}

@media screen and (max-width: 768px) {
    padding: 45px 0 30px 0;
    gap: 20px;
}
`;

const TitleWrapper = styled.div`
text-align: center;

div {
    display: inline-flex;
}
`;

const ContactUs = () => {
    return (
        <RootContainer>
            <Topbar />
            <Row>
                <Col xs={1} xl={2} xxl={4} />
                <Col xs={22} xl={20} xxl={16}>
                    <Container>
                        <TitleWrapper>
                            <Title>
                                We Want to Hear From You
                            </Title>
                        </TitleWrapper>
                        <ContactForm />
                    </Container>
                </Col>
                <Col xs={1} xl={2} xxl={4} />
            </Row>
            <Map />
        </RootContainer>
    );
}

export default ContactUs;