import React from "react";
import Title from "../../Title/Title";
import styled from "styled-components";
import { MenuOutlined } from "@ant-design/icons";
// import playstore_img from "../../../assets/playstore.png";
// import appstore_img from "../../../assets/appstore.png";
import cook_img from "../../../assets/cook_img.png";
import myanmar_ethnicity from "../../../assets/myanmar_ethnicity.png";
import { Col, Row as AntRow } from "antd";

const Row = styled(AntRow)`
	align-items: center !important;
`;

const CustomRow = styled(AntRow)`
	padding-top: 50px;
	align-items: center !important;

	@media screen and (max-width: 992px) {
		padding-top: 30px;
	}
`;

const SubTitle = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	grid-gap: 12px;

	font-size: 24px;
	font-weight: 500;
	font-style: normal;
	padding: 25px 0;
	color: #000;
	text-transform: capitalize;

	svg {
		color: #01b0eb;
		font-size: 20px;
		margin-bottom: -2px;
	}

	@media screen and (max-width: 1600px) {
		font-size: 19px;
	}
`;

const Descriptions = styled.div`
	font-size: 18px;
	font-weight: normal;
	font-style: normal;
	color: #000;
	line-height: 1.5;
	letter-spacing: normal;
	padding-bottom: 30px;
	text-align: left;

	@media screen and (max-width: 1600px) {
		font-size: 16px;
	}

	@media screen and (max-width: 1200px) {
		font-size: 15px;
	}

	@media screen and (max-width: 992px) {
		font-size: 14px;
	}

	@media screen and (max-width: 768px) {
		font-size: 13px;
	}
`;

// const Bottom = styled(Space)`
// 	gap: 30px !important;

// 	@media screen and (max-width: 1600px) {
// 		gap: 25px !important;
// 	}

// 	@media screen and (max-width: 1200px) {
// 		gap: 20px !important;
// 	}

// 	@media screen and (max-width: 992px) {
// 		gap: 15px !important;
// 	}
// `;

// const StoreImage = styled.img.attrs({
// 	alt: "storeimg",
// })`
// 	width: 170px;
// 	cursor: pointer;

// 	@media screen and (max-width: 1600px) {
// 		width: 140px;
// 	}

// 	@media screen and (max-width: 1200px) {
// 		width: 120px;
// 	}

// 	@media screen and (max-width: 992px) {
// 		width: 100px;
// 	}
// `;

const Image = styled.img.attrs({
	alt: "img",
})`
	width: 100%;
`;

const ImageWrapper = styled.div`
	padding-left: 30px;
`;

const Right = styled.div`
	text-align: right;

	div {
		flex-direction: row-reverse;
		text-align: right;
	}

	#linx-icon {
		right: -7px;
		left: auto;
		transform: rotate(180deg);
	}

	#linx-text {
		padding-right: 28px;
	}
`;

const Card = ({ title, subTitle, description }) => (
	<>
		<Title>{title}</Title>
		<SubTitle>
			<MenuOutlined />
			<span>{subTitle}</span>
		</SubTitle>
		<Descriptions>{description}</Descriptions>
		{/* <Bottom>
            <StoreImage src={playstore_img} />
            <StoreImage src={appstore_img} />
        </Bottom> */}
	</>
);

const Products = () => {
	return (
		<>
			<Row gutter={[0, 30]}>
				<Col xs={1} sm={1} xl={2} xxl={4} />
				<Col xs={22} sm={11} xl={10} xxl={8}>
					<Card
						title="How To Cook"
						subTitle="Cook like a pro with just a tap!"
						description="This application can provide users with helpful guidance on various cooking techniques and methods, making it a valuable resource for novice and experienced cooks alike. With this application, users can access a range of recipes and cooking tips that can help them prepare delicious meals and improve their culinary skills."
					/>
				</Col>
				<Col xs={1} sm={0} />
				<Col xs={24} sm={12} xl={12} xxl={12}>
					<ImageWrapper>
						<Image src={cook_img} />
					</ImageWrapper>
				</Col>
			</Row>
			<CustomRow gutter={[0, 10]}>
				<Col xs={23} sm={12} xl={12} xxl={12}>
					<Image src={myanmar_ethnicity} />
				</Col>
				<Col xs={1} sm={0} />
				<Col xs={1} sm={0} />
				<Col xs={22} sm={11} xl={10} xxl={8}>
					<Right>
						<Card
							title="Myanmar Ethnicity"
							subTitle="Ethnic Diversity & Ethnic Relations"
							description="The Republic of the Union of Myanmar is one of the largest countries in Southeast Asia. Myanmar has a rich history and cultural tradition, visible in festivals (pwe), food, remains of past kingdoms and temples. The majority of the population are Bamar Buddhists; however, there is an incredible cultural variety. This diversity is especially visible in the Burmese migrant populations across the globe. Despite varying traditions and customs across different ethnic and religious backgrounds, there are some values that can be attributed to most people in the country. The Burmese are typically gentle, considerate, good-humored and patient people. They appear to have a lot of gratitude for their situation, as well as hope for the future. This aspect of the Burmese character is particularly commendable when considering that the country continues to endure one of the world’s longest-running conflicts."
						/>
					</Right>
				</Col>
				<Col xs={1} sm={1} xl={2} xxl={4} />
			</CustomRow>
		</>
	);
};

export default Products;
