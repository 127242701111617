import { Col, Row } from 'antd';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Header,
    Logo,
    Menu,
    Indicator,
    IconWrapper,
    ContactButton,
    Space,
    MobileMenuIcon,
} from './Appbar.style';
import MobileDrawer from './MobileDrawer/MobileDrawer';
import {
    HOME,
    ABOUT_US,
    OUR_SERVICES,
    // CAREERS,
    CONTACT_US,
} from '../../route.constants';

const Icon = () => (
    <IconWrapper>
        <Indicator />
    </IconWrapper>
);

const navs = [
    {
        label: 'Home',
        icon: <Icon />,
        key: HOME,
    },
    {
        label: 'About Us',
        icon: <Icon />,
        key: ABOUT_US,
    },
    {
        label: 'Our Services',
        icon: <Icon />,
        key: OUR_SERVICES,
    },
    // {
    //     label: 'Careers',
    //     icon: <Icon />,
    //     key: CAREERS,
    // },
    {
        label: <ContactButton>Contact Us</ContactButton>,
        key: CONTACT_US,
    },
]

const Appbar = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [color, setColor] = useState(pathname !== HOME && 'filled');
    const [open, setOpen] = useState(false);

    window.onscroll = () => {
        if (window.scrollY > 20) {
            setColor('filled');
        }
        else {
            pathname === HOME && setColor('');
        }
    };

    const goToHome = () => {
        navigate('/');
    }

    const handleMenuClicked = e => {
        navigate(e.key);
    }

    return (
        <Header color={color.toString()}>
            <Row>
                <Col xs={1} xl={2} xxl={4} />
                <Col xs={22} xl={20} xxl={16} >
                    <Space>
                        <Logo onClick={goToHome} />
                        <Menu
                            mode="horizontal"
                            selectedKeys={[pathname]}
                            items={navs}
                            onClick={handleMenuClicked}
                        />
                        <MobileMenuIcon
                            onClick={() => setOpen(true)}
                        />
                    </Space>
                </Col>
                <Col xs={1} xl={2} xxl={4} />
            </Row>
            <MobileDrawer
                navs={navs}
                open={open}
                setOpen={setOpen}
            />
        </Header>
    );
}

export default Appbar;