import React from 'react';
import {
    CloseCircleFilled,
    EnvironmentFilled,
    MenuOutlined,
} from '@ant-design/icons';
import {
    Modal,
    LocationText,
    Location,
    DateBox,
    SectionBox,
    Container,
    SectionDesc,
    SectionLabel,
    SectionSpace,
} from './JobDetailsModal.style';

const Section = ({
    title,
    children,
}) => (
    <SectionSpace>
        <SectionBox>
            <MenuOutlined />
            <div>{title}</div>
        </SectionBox>
        <SectionDesc>
            {children}
        </SectionDesc>
    </SectionSpace>
);

const JobDetailsModal = ({
    item,
    open,
    setOpen,
}) => {
    return (
        <Modal
            centered
            destroyOnClose
            footer={false}
            open={open}
            onCancel={() => setOpen(false)}
            title={item.title}
            closeIcon={<CloseCircleFilled />}
        >
            <Container>
                <Location>
                    <EnvironmentFilled />
                    <LocationText>{`${item.location}, Myanmar`}</LocationText>
                </Location>
                <DateBox>{`Last Apply Date - ${item.last_apply_date}`}</DateBox>
                <Section title='Description'>
                    {item.description}
                </Section>
                <Section title='Requirements'>
                    <SectionLabel>
                        Core Skills
                    </SectionLabel>
                    <ul>
                        {item.requirements?.core_skills.map((x, key) => (
                            <li key={key}>
                                {x}
                            </li>
                        ))}
                    </ul>
                    <SectionLabel>
                        Required Skills
                    </SectionLabel>
                    <ul>
                        {item.requirements?.required_skills.map((x, key) => (
                            <li key={key}>
                                {x}
                            </li>
                        ))}
                    </ul>
                    <SectionLabel>
                        Useful Skills
                    </SectionLabel>
                    <ul>
                        {item.requirements?.useful_skills.map((x, key) => (
                            <li key={key}>
                                {x}
                            </li>
                        ))}
                    </ul>
                </Section>
                <Section title='About Job'>
                    <SectionLabel>Work Location</SectionLabel>
                    <ul>
                        <li>{item.work_location}</li>
                    </ul>
                    <SectionLabel>Working Hour</SectionLabel>
                    <ul>
                        <li>{item.working_hour}</li>
                    </ul>
                </Section>
                <SectionLabel>
                    {`${item.type}`}
                </SectionLabel>
            </Container>
        </Modal>
    );
}

export default JobDetailsModal;