import styled from 'styled-components';
import {
    Space,
} from 'antd';
import Img from '../../../assets/banner_img.png';

export const Container = styled.div`
position: relative;
background: #0F173B;

@media screen and (max-width: 992px) {
height: 500px;

#wrapper {
    padding-top: 60px;
}
}

@media screen and (max-width: 575px) {
height: 860px;
}

@media screen and (max-width: 425px) {
height: 740px;
}
`;

export const BannerImage = styled.img.attrs({
    alt: 'b-img',
    src: Img,
})`
width: 100%;
height: auto;
object-fit: cover;
position: relative;
z-index: 0;
aspect-ratio: 96 / 49;

@media screen and (max-width: 992px) {
height: 500px;
}

@media screen and (max-width: 575px) {
height: 860px;
}

@media screen and (max-width: 425px) {
height: 740px;
}
`;

export const Content = styled.div`
text-align: center;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -32%);
width: 100%;

.ant-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

@media screen and (max-width: 992px) {
    transform: translate(-50%, -42%);
}
`;

export const Fieldset = styled.fieldset`
display: inline;
border: 3px solid #FFFFFF;
rotate: 90deg;
padding-left: 26px;
padding-right: 80px;
padding-top: 10px;
padding-bottom: 0;
margin-top: 0;
margin-left: -40px;

@media screen and (max-width: 1600px) {
    padding-left: 15px;
}

@media screen and (max-width: 992px) {
    padding-top: 25px;
    padding-left: 8px;
    margin-left: -35px;
}
`;

export const Legend = styled.legend`
rotate: -90deg;
width: 160px;
padding-inline-start: 40px;
text-align: left;

@media screen and (max-width: 1600px) {
    width: 100px;
    padding-inline-start: 20px;
}

@media screen and (max-width: 1200px) {
    width: 80px;
}

@media screen and (max-width: 992px) {
    width: 70px;
}
`;

export const Title = styled.div`
font-size: ${props => props.size === 'large' ? '70px' : '30px'};
font-weight: ${props => props.size === 'large' ? '600' : 'normal'};
color: #01B0EB;
text-transform: capitalize;
min-width: 425px;
width: 500px;

@media screen and (max-width: 1600px) {
    font-size: ${props => props.size === 'large' ? '50px' : '20px'};
}

@media screen and (max-width: 1200px) {
    font-size: ${props => props.size === 'large' ? '30px' : '20px'};
}

@media screen and (max-width: 992px) {
    font-size: ${props => props.size === 'large' ? '25px' : '20px'};
}
`;

export const Description = styled.div`
font-size: 16px;
font-weight: 300;
font-style: normal;
color: #E7E7E7;
line-height: 1.5;
text-align: left;
padding-left: 134px;

@media screen and (max-width: 1600px) {
    font-size: 15px;
    padding-left: 100px;
    margin-top: -15px;
}

@media screen and (max-width: 1200px) {
    font-size: 14px;
    padding-left: 80px;
    margin-top: -15px;
}

@media screen and (max-width: 992px) {
    font-size: 13px;
    padding-left: 75px;
    margin-top: -30px;
}
`;

export const SlideImage = styled.img.attrs({
    alt: 'slideimg',
})`
width: 100%;
`;

export const LeftBar = styled(Space).attrs({
    direction: 'vertical',
    size: 'middle',
})`
position: absolute;
left: 30px;
bottom: 50px;
align-items: center;
justify-content: center;

.ant-space-item {
    display: inherit;
}

@media screen and (max-width: 1200px) {
display: none;
}
`;

export const Bar = styled.div`
height: ${props => props.size === 'small' ? '60px' : '200px'};
border-left: 2px solid #FFFFFF;
padding-bottom: ${props => props.size === 'small' ? '0' : '20px'};

@media screen and (max-width: 1600px) {
    height: ${props => props.size === 'small' ? '50px' : '150px'};
}

@media screen and (max-width: 1200px) {
    height: ${props => props.size === 'small' ? '40px' : '100px'};
}

@media screen and (max-width: 992px) {
    height: ${props => props.size === 'small' ? '30px' : '80px'};
}

`;

export const RightBar = styled(Space).attrs({
    direction: 'vertical',
    size: 'large',
})`
position: absolute;
right: 0;
bottom: 50px;
align-items: center;
justify-content: center;

@media screen and (max-width: 1200px) {
display: none;
}
`;

export const SlideLabel = styled.div`
height: 115px;
min-width: 117px;
display: flex;
align-items: center;
justify-content: center;

div {
    rotate: -90deg;

    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    color: #fff;
}

@media screen and (max-width: 1600px) {
    
    div {
        font-size: 15px;
    }
}

@media screen and (max-width: 1200px) {

    div {
        font-size: 14px;
    }
}

@media screen and (max-width: 992px) {
    
    div {
        font-size: 13px;
    }
}

@media screen and (max-width: 768px) {

    div {
        font-size: 12px;
    }
}
`;

export const ButtonWrapper = styled.div`
margin-left: 134px;
margin-top: 50px;

@media screen and (max-width: 1600px) {
    margin-left: 100px;
    margin-top: 30px;
}

@media screen and (max-width: 1200px) {
    margin-left: 80px;
}

@media screen and (max-width: 992px) {
    margin-left: 75px;
}

@media screen and (max-width: 768px) {
    margin-left: 75px;
}

@media screen and (max-width: 575px) {
    margin-left: 0;
    width: 100%;
    text-align: center;
    padding-bottom: 30px;
}
`;