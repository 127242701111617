import React from 'react';
import {
    Topbar,
    Title,
} from '../../components';
import styled from 'styled-components';
import { Col, Row } from 'antd';
import img1 from '../../assets/smart_team.jpg';
import img2 from '../../assets/smart_team2.jpg';

const RootContainer = styled.div`
min-height: 100vh;
`;

const Container = styled.div`
width: 100%;
display: flex;
flex-direction: column;
gap: 40px;
padding: 70px 0;
justify-content: center;

@media screen and (max-width: 1600px) {
    padding: 60px 0;
}

@media screen and (max-width: 1200px) {
    padding: 45px 0;
    gap: 30px;
}

@media screen and (max-width: 768px) {
    gap: 20px;
}
`;

const TitleWrapper = styled.div`
text-align: center;

div {
    display: inline-flex;
}
`;

const Text = styled.div`
font-size: 18px;
font-weight: normal;
font-style: normal;
letter-spacing: normal;
line-height: 1.5;
color: #015ABA;
padding-bottom: 20px;
text-align: justify;

@media screen and (max-width: 1600px) {
    font-size: 16px;
}

@media screen and (max-width: 1200px) {
    font-size: 15px;
}

@media screen and (max-width: 992px) {
    font-size: 14px;
}

@media screen and (max-width: 768px) {
    font-size: 13px;
}
`;

const Tagline = styled(Text)`
color: #000000;
text-align: center;
padding-bottom: 0;
`;

const Image = styled.img.attrs({
    alt: 'img',
})`
width: 100%;
border-radius: 10px;
`;

const Content = () => {
    return (
        <Container>
            <TitleWrapper>
                <Title>
                    About Us
                </Title>
            </TitleWrapper>
            <div>
                <Text>
                    Web services are a type of software system designed to facilitate communication and data exchange between different web applications over the internet. A web application is a software program that runs on a web server and is accessed through a web browser. Web applications can range from simple static websites to complex dynamic applications with sophisticated functionality. Web applications are ubiquitous in today's digital world and are used for a wide range of purposes, including e-commerce, social networking, and business management.
                </Text>
            </div>
            <TitleWrapper>
                <Title showIcon={false}>
                    Smart Team, Smarter Results, Don't Miss Opportunity!
                </Title>
            </TitleWrapper>
            <div>
                <Tagline>
                    We transform complexity into simplicity with smart technology solutions.
                </Tagline>
                <Tagline>
                    We always invite any innovative and talented person.
                </Tagline>
            </div>
            <Row gutter={{ xs: 10, xl: 20, xxl: 30, }}>
                <Col xs={12}>
                    <Image src={img1} />
                </Col>
                <Col xs={12}>
                    <Image src={img2} />
                </Col>
            </Row>
        </Container>
    );
}

const AboutUs = () => {
    return (
        <RootContainer>
            <Topbar />
            <Row>
                <Col xs={1} xl={2} xxl={4} />
                <Col xs={22} xl={20} xxl={16}>
                    <Content />
                </Col>
                <Col xs={1} xl={2} xxl={4} />
            </Row>
        </RootContainer>
    )
}

export default AboutUs;