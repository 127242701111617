import styled, { createGlobalStyle } from "styled-components";
import FbIcon from '../assets/fb_icon.svg';
import TgIcon from '../assets/tg_icon.svg';
import TwIcon from '../assets/twitter_icon.svg';

const GlobalStyles = createGlobalStyle`
* {
    font-family: 'Outfit';
}

body {
    margin: 0;
}

.ant-layout {
    background: #fff !important;
}
`;

export const Icon = styled.img`
width: 46px;
height: auto;
object-fit: contain;
cursor: pointer;

@media screen and (max-width: 1600px) {
    width: 35px;
}
`;

export const FacebookIcon = styled(Icon).attrs({
    alt: 'fbicon',
    src: FbIcon,
})``;

export const TelegramIcon = styled(Icon).attrs({
    alt: 'tgicon',
    src: TgIcon,
})``;

export const TwitterIcon = styled(Icon).attrs({
    alt: 'fbicon',
    src: TwIcon,
})``;

export default GlobalStyles;