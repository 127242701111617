import slider_img_1 from '../assets/slider_img_1.png';
import slider_img_2 from '../assets/slider_img_2.png';

export const slides = [
    {
        key: 0,
        title1: 'Providing the best services',
        title2: '& IT Solutions',
        description: "DIMINGALARGIT DIGITAL CO., LTD (DMG) is a leading technology company based in Myanmar, providing innovative IT solutions to various industries. Established in 2023, DMG will leading to become a trusted partner of businesses across the country, delivering high-quality technology services and products.",
        link: '',
        image: slider_img_1,
    },
    {
        key: 1,
        title1: 'Providing the best services',
        title2: '& Web Solutions',
        description: "DIMINGALARGIT DIGITAL CO., LTD (DMG) specializes in software development, mobile app development and web design and development. DMG employs a team of highly skilled and experienced professionals who are passionate highly technological standard to deliver qualified products to their clients.",
        link: '',
        image: slider_img_2,
    },
];