import React from 'react';
import styled from 'styled-components';
import {
    RightCircleFilled,
} from '@ant-design/icons';
import { Button as AntButton } from 'antd';

const StyledButton = styled(AntButton)`
background-color: #015ABA !important;
border-radius: 26px;
display: inline-flex;
flex-direction: row-reverse;
align-items: center;
justify-content: center;
grid-gap: 10px;
height: auto;
padding: 5px 5px 5px 15px !important;
overflow: hidden;
position: relative;
transition: all 0.3s;
z-index: 1;

font-size: 18px;
font-weight: normal;
font-style: normal;
color: #fff;

    svg {
        font-size: 38px;
    }

    :after{
        content: '';
        position: absolute;
        z-index: -1;
        transition: all 0.3s;
        box-sizing: border-box;
        border-radius: 26px;
    }

    :after {
        width: 0;
        height: 100%;
        top: 0;
        left: 0;
        background: #01B0EB;
    }

    :hover,
    :active {
        span:not(.anticon) {
            color: #000 !important;
        }
    }

    :hover:after,
    :active:after {
        width: 100%;
    }


@media screen and (max-width: 1600px) {
    font-size: 16px;

    svg {
        font-size: 35px;
    }
}

@media screen and (max-width: 1200px) {
    font-size: 15px;

    svg {
        font-size: 30px;
    }
}

@media screen and (max-width: 992px) {
    font-size: 14px;
    padding: 5px 5px 5px 10px !important;

    svg {
        font-size: 28px;
    }
}

@media screen and (max-width: 768px) {
    font-size: 13px;
}
`;

const Button = ({
    children,
    onClick,
    ...props
}) => {
    return (
        <StyledButton
            {...props}
            type='primary'
            icon={<RightCircleFilled />}
            onClick={onClick}
        >
            {children}
        </StyledButton>
    );
}

export default Button;