import React from 'react';
import styled from 'styled-components';

const Box = styled.div`
display: flex;
align-items: center;
justify-content: flex-start;
position: relative;
padding-bottom: 15px;

@media screen and (max-width: 575px) {
display: inline-flex;
}
`;

const Text = styled.div.attrs({
  id: 'linx-text'
})`
color: #0165C0;
font-size: 45px;
font-weight: 600;
font-style: normal;

@media screen and (max-width: 1600px) {
  font-size: 38px;
}

@media screen and (max-width: 1200px) {
  font-size: 33px;
}

@media screen and (max-width: 992px) {
  font-size: 28px;
}

@media screen and (max-width: 768px) {
  font-size: 23px;
}
`;

const Title = ({
  showIcon = true,
  children,
}) => {
  return (
    <Box margin={showIcon ? "left" : "none"}>
      <Text>
        {children}
      </Text>
    </Box>
  );
}

export default Title;