import React, { useState } from 'react';
import {
    Container,
    BannerImage,
    Content,
    Title,
    Fieldset,
    Legend,
    SlideImage,
    Description,
    // LeftBar,
    Bar,
    RightBar,
    SlideLabel,
    ButtonWrapper,
} from './Banner.style';
import {
    // TelegramIcon,
    // TwitterIcon,
    // FacebookIcon,
} from '../../../styles';
import {
    Col,
    Row,
} from 'antd';
import Button from '../../Button/Button';
import { slides } from '../../../settings/slides';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import { useNavigate } from 'react-router-dom';
import { OUR_SERVICES } from '../../../route.constants';

const Banner = () => {
    const navigate = useNavigate();
    const [current, setCurrent] = useState(0);

    const handleClicked = () => {
        navigate(OUR_SERVICES);
    }

    // const SocialsBar = () => (
    //     <LeftBar>
    //         <Bar />
    //         <TelegramIcon />
    //         <TwitterIcon />
    //         <FacebookIcon />
    //         <Bar size='small' />
    //     </LeftBar>
    // );

    const SliderBar = () => (
        <RightBar>
            <Bar />
            <SlideLabel>
                <div>{current + 1}&nbsp;&nbsp;&nbsp;of&nbsp;&nbsp;&nbsp;{slides.length}&nbsp;&nbsp;&nbsp;Slides</div>
            </SlideLabel>
            <Bar size='small' />
        </RightBar>
    )

    return (
        <Container>
            <BannerImage />
            <Content>
                <Fade
                    autoplay
                    arrows={false}
                    pauseOnHover={false}
                    defaultIndex={current}
                    onStartChange={(from, to) => setCurrent(to)}
                >
                    {slides.map((current, key) => (
                        <Row key={key}>
                            <Col xs={1} sm={1} xl={2} xxl={4} />
                            <Col xs={22} sm={10} xl={9} xxl={7} id='wrapper'>
                                <Fieldset>
                                    <Legend>
                                        <Title>{current.title1}</Title>
                                        <Title size='large'>{current.title2}</Title>
                                    </Legend>
                                </Fieldset>
                                <Description>
                                    {current.description}
                                </Description>
                                <ButtonWrapper>
                                    <Button onClick={handleClicked}>
                                        Let's Get Started
                                    </Button>
                                </ButtonWrapper>
                            </Col>
                            <Col xs={1} />
                            <Col xs={1} sm={1} xl={1} xxl={1} />
                            <Col xs={22} sm={10} xl={9} xxl={8}>
                                <SlideImage src={current.image} />
                            </Col>
                            <Col xs={1} sm={1} xl={2} xxl={3} />
                        </Row>
                    ))}
                </Fade>
            </Content>
            {/* <SocialsBar /> */}
            <SliderBar />
        </Container>
    );
}

export default Banner;