import React, { useState } from 'react';
import {
  Box,
  Container,
  Item,
  Text,
  TextBlue,
  TextBold,
  TitleWrapper,
} from './FindPosition.style';
import Title from '../../Title/Title';
import { Col, Row } from 'antd';
import { positions } from '../../../settings';
import JobDetailsModal from '../../JobDetailsModal/JobDetailsModal';
import Button from '../../Button/Button';
import { useNavigate } from 'react-router-dom';
import { CAREERS } from '../../../route.constants';

const FindPosition = ({
  isHome = true,
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  const _positions = isHome ? positions.slice(0, 3) : positions;

  const handleClicked = item => {
    setSelectedItem(item);
    setOpen(true);
  }

  const seeAll = () => {
    navigate(CAREERS);
  }

  return (
    <>
      <Container>
        <Row>
          <Col xs={1} xl={2} xxl={4} />
          <Col xs={22} xl={20} xxl={16}>
            <TitleWrapper>
              <Title>
                Let's find your job position
              </Title>
            </TitleWrapper>
            <Text>
              Find the right job for you no matter what it is that you do.
            </Text>
            <Row>
              <Col xs={0} md={1} lg={3} />
              <Col xs={24} md={22} lg={18}>
                <Box>
                  {_positions.map((position, key) => (
                    <Item
                      key={key}
                      onClick={() => handleClicked(position)}
                    >
                      <div>
                        <Text>
                          {position.category}
                        </Text>
                        <br />
                        <TextBlue>
                          {position.title}
                        </TextBlue>
                      </div>
                      <TextBold>{`${position.location} (${position.type})`}</TextBold>
                    </Item>
                  ))}
                </Box>
                {isHome &&
                  <Button onClick={seeAll}>
                    See All Careers
                  </Button>
                }
              </Col>
              <Col xs={0} md={1} lg={3} />
            </Row>
          </Col>
          <Col xs={1} xl={2} xxl={4} />
        </Row>
      </Container>
      <JobDetailsModal
        item={selectedItem}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}

export default FindPosition;