import { Col, Form, Input, message, Row } from 'antd';
import React, { useState } from 'react';
import Button from '../../Button/Button';
import styled from 'styled-components';
import emailjs from '@emailjs/browser';
import {
    EMAILJS_SERVICE_ID,
    EMAILJS_TEMPLATE_ID,
    EMAILJS_PUBLIC_KEY,
} from '../../../config';

const ButtonWrapper = styled.div`
text-align: center;
`;

const ContactForm = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const sendEmail = ({ contactform }) => {
        if (loading) {
            return;
        }
        setLoading(true);
        emailjs
            .send(EMAILJS_SERVICE_ID, EMAILJS_TEMPLATE_ID, contactform, EMAILJS_PUBLIC_KEY)
            .then(() => {
                setLoading(false);
                message.success('Success!');
                form.resetFields();
            }, (error) => {
                setLoading(false);
                message.error(error);
            });
    };

    return (
        <>
            <Form
                form={form}
                layout='vertical'
                onFinish={sendEmail}
            >
                <Form.List name='contactform'>
                    {() => (
                        <Row gutter={{ xs: 10, sm: 20, md: 30 }}>
                            <Col xs={12}>
                                <Form.Item
                                    label='First Name'
                                    name='first_name'
                                    rules={[{
                                        required: true,
                                        message: '',
                                    }]}
                                >
                                    <Input size='large' />
                                </Form.Item>
                            </Col>
                            <Col xs={12}>
                                <Form.Item
                                    label='Last Name'
                                    name='last_name'
                                    rules={[{
                                        required: true,
                                        message: '',
                                    }]}
                                >
                                    <Input size='large' />
                                </Form.Item>
                            </Col>
                            <Col xs={12}>
                                <Form.Item
                                    label='Email'
                                    name='email'
                                    rules={[
                                        {
                                            type: 'email',
                                            message: '',
                                        },
                                        {
                                            required: true,
                                            message: '',
                                        },
                                    ]}
                                >
                                    <Input size='large' />
                                </Form.Item>
                            </Col>
                            <Col xs={12}>
                                <Form.Item
                                    label='Phone'
                                    name='phone'
                                    rules={[
                                        {
                                            required: true,
                                            message: '',
                                        },
                                    ]}
                                >
                                    <Input size='large' />
                                </Form.Item>
                            </Col>
                            <Col xs={24}>
                                <Form.Item
                                    label='Subject'
                                    name='subject'
                                    rules={[{
                                        required: true,
                                        message: '',
                                    }]}
                                >
                                    <Input size='large' />
                                </Form.Item>
                            </Col>
                            <Col xs={24}>
                                <Form.Item
                                    label='Message'
                                    name='message'
                                    rules={[{
                                        required: true,
                                        message: '',
                                    }]}
                                >
                                    <Input.TextArea
                                        rows={8}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24}>
                                <ButtonWrapper>
                                    <Button
                                        loading={loading}
                                        htmlType='submit'
                                    >
                                        Send Email
                                    </Button>
                                </ButtonWrapper>
                            </Col>
                        </Row>
                    )}
                </Form.List>
            </Form>
        </>
    )
}

export default ContactForm;