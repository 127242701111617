import styled from "styled-components";
import bgImg from '../../../assets/get_started_img.png';

export const Container = styled.div`
text-align: center;
position: relative;
height: 100%;
`;

export const BackgroundImage = styled.img.attrs({
    alt: 'bgimage',
    src: bgImg,
})`
width: 100%;
height: 100%;
min-height: 316px;
object-fit: cover;
border-radius: 18px;

@media screen and (max-width: 1600px) {
    min-height: 280px;
}

@media screen and (max-width: 1200px) {
    min-height: 250px;
}

@media screen and (max-width: 992px) {
    min-height: 200px;
}

@media screen and (max-width: 768px) {
    min-height: 150px;
}
`;

export const Title = styled.div`
font-size: 45px;
font-weight: 600;
font-style: normal;
color: #fff;

@media screen and (max-width: 1600px) {
    font-size: 35px;
}

@media screen and (max-width: 1200px) {
    font-size: 30px;
}

@media screen and (max-width: 992px) {
    font-size: 25px;
}

@media screen and (max-width: 768px) {
    font-size: 20px;
}
`;

export const Text = styled.div`
font-size: 24px;
font-weight: 600;
font-style: normal;
color: #000;
padding: 24px 0;

@media screen and (max-width: 1600px) {
    font-size: 20px;
}

@media screen and (max-width: 1200px) {
    font-size: 18px;
    padding: 20px 0;
}

@media screen and (max-width: 992px) {
    font-size: 16px;
    padding: 18px 8px;
}

@media screen and (max-width: 768px) {
    font-size: 14px;
    padding: 16px 8px;
}
`;

export const ContentWrapper = styled.div`
width: 100%;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);

@media screen and (max-width: 768px) {
padding: 20px 0;
}
`;