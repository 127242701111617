import React from 'react';
import {
    Drawer,
    Indicator,
    MobileLogo,
    Nav,
    NavItem,
} from '../Appbar.style';
import {
    HOME,
} from '../../../route.constants';
import { useLocation, useNavigate } from 'react-router-dom';

const MobileDrawer = ({
    navs,
    open,
    setOpen,
}) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const handleMenuClicked = (path) => {
        navigate(path);
        setOpen(false);
    }

    return (
        <Drawer
            title={<MobileLogo onClick={() => handleMenuClicked(HOME)} />}
            placement="right"
            onClose={() => setOpen(false)}
            open={open}
            width={'65vw'}
        >
            {navs.map((nav, key) => (
                <NavItem key={key}>
                    <Nav onClick={() => handleMenuClicked(nav.key)}>
                        {pathname === nav.key && <Indicator />}
                        {nav.label}
                    </Nav>
                </NavItem>
            ))}
        </Drawer>
    )
}

export default MobileDrawer;